import React, { useState } from "react";
import "./App.css";

function App() {
  const [side, setSide] = useState(null); // current coin result
  const [flipping, setFlipping] = useState(false); // flipping animation state
  const [history, setHistory] = useState([]); // history of flips
  const [headsCount, setHeadsCount] = useState(0); // track heads count
  const [tailsCount, setTailsCount] = useState(0); // track tails count

  const coinFlip = () => {
    setFlipping(true);

    setTimeout(() => {
      const result = Math.random() > 0.5 ? "Heads" : "Tails";
      setSide(result);
      setFlipping(false);

      // Update the history with the latest result, keeping only the last 10
      setHistory((prevHistory) => {
        const newHistory = [result, ...prevHistory];
        return newHistory.slice(0, 10); // Keep only the last 10 results
      });

      // Update counts
      if (result === "Heads") {
        setHeadsCount((prev) => prev + 1);
      } else {
        setTailsCount((prev) => prev + 1);
      }
    }, 1000);
  };

  const totalFlips = headsCount + tailsCount;
  const headsPercentage =
    totalFlips === 0 ? 0 : ((headsCount / totalFlips) * 100).toFixed(2);
  const tailsPercentage =
    totalFlips === 0 ? 0 : ((tailsCount / totalFlips) * 100).toFixed(2);

  return (
    <div className="App">
      <h1>Coin Flip</h1>
      <div className={`coin ${flipping ? "flipping" : ""}`}>
        {side ? side : "Click to flip"}
      </div>
      <button onClick={coinFlip} disabled={flipping}>
        Flip Coin
      </button>
      {/* Display percentages */}
      <div className="percentages">
        <h2>Statistics</h2>
        <p>Total Flips: {totalFlips}</p>
        <p>
          Heads: {headsCount} ({headsPercentage}%)
        </p>
        <p>
          Tails: {tailsCount} ({tailsPercentage}%)
        </p>
      </div>
      {/* Display the last 10 flip results */}
      <div className="history">
        <h2>Last 10 Results</h2>
        <ul>
          {history.map((flip, index) => (
            <li key={index}>{flip}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default App;
